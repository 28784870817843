.items-grid-container {
  padding: 20px;
  max-width: 1440px;
  margin: 0 auto;
}

.items-grid {
  display: grid;
  gap: 16px;
  grid-auto-rows: 1fr;
}

.item-card {
  background: #ffffff;
  border-radius: 12px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: transform 0.2s ease;
  min-height: 150px;
  display: flex;
}

.item-card:hover {
  transform: translateY(-4px);
}

.item-content {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  width: 100%;
  font-size: calc(12px + 0.5vw - 0.5vh);
  aspect-ratio: 3/4;
  box-sizing: border-box;
}

.preview-container {
  position: relative;
  width: 100%;
  height: 100%;
  overflow: visible; /* Разрешаем выход за границы */
  background: #fff;
  border-radius: 8px;
}

.preview-element {
  pointer-events: none;
  object-fit: contain;
  width: 100%;
  height: 100%;
}

.preview-text {
  color: #333;
  font-family: Arial;
  line-height: 1.2;
  word-break: break-word; /* Перенос слов */
  transform: none;
  width: max-content;
}

.preview-shape {
  border: 1px solid rgba(0,0,0,0.1);
  box-sizing: border-box;
}

.item-content {
  aspect-ratio: 3/4;
  position: relative;
  background: white;
  border-radius: 8px;
  overflow: hidden;
}

/* Адаптивные стили */
@media (min-width: 1200px) {
  .items-grid { grid-template-columns: repeat(5, 1fr); }
}

@media (min-width: 768px) and (max-width: 1199px) {
  .items-grid { grid-template-columns: repeat(4, 1fr); }
}

@media (min-width: 480px) and (max-width: 767px) {
  .items-grid { grid-template-columns: repeat(2, 1fr); }
}

@media (max-width: 479px) {
  .items-grid { grid-template-columns: 1fr; }
  .item-card { min-height: 120px; }
}