.search-header {
  text-align: center;
  padding: 20px;
  margin: 10px;
  background-color: transparent;
  transition: all 0.5s ease;
  position: relative;
  margin-top: 30vh;
}

.search-header.active {
  margin-top: 20px;
  background-color: #f0f0f0;
  box-shadow: 0 2px 10px rgba(0,0,0,0.1);
}

.search-wrapper {
  display: flex;
  justify-content: center;
  max-width: 800px;
  margin: 15px auto;
  transition: transform 0.3s ease;
}

.input-container {
  position: relative;
  flex-grow: 1;
}

input {
  width: 95.5%;
  padding: 15px;
  border: 2px solid #007bff;
  border-radius: 25px 0 0 25px;
  font-size: 16px;
  outline: none;
  transition: all 0.3s ease;
}

input:invalid {
  border-color: #dc3545;
  box-shadow: 0 0 0 0.2rem rgba(220,53,69,.25);
}

input:focus:invalid {
  box-shadow: 0 0 0 0.2rem rgba(220,53,69,.25);
}

.clear-button {
  position: absolute;
  right: 5px;
  top: 50%;
  transform: translateY(-50%);
  background: none;
  border: none;
  cursor: pointer;
  font-size: 20px;
  color: #999;
  padding: 0 5px;
}

.clear-button:hover {
  color: #666;
}

.search-button {
  padding: 10px 25px;
  background-color: #007bff;
  color: white;
  border: 2px solid #007bff;
  border-radius: 0 25px 25px 0;
  cursor: pointer;
  font-size: 16px;
  transition: background-color 0.3s;
}

.search-button:hover {
  background-color: #0056b3;
  border-color: #0056b3;
}

h2 {
  margin: 0;
  font-size: 32px;
  font-style: normal;
}

.answer {
  text-align: center;
}

