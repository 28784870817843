.generator-container {
  padding: 15px;
  font-family: Arial;
  display: flex;
  flex-direction: column;
}

.header-section {
  padding: 15px;
  text-align: center;
  margin-bottom: 18px;
  background-color: #f5f5f5;
  border-radius: 8px;
  position: relative;
}

.header-section h2 {
  color: #333;
  margin-bottom: 10px;
}

.header-section p {
  color: #666;
  margin-bottom: 30px;
}

.button-back {
  position: absolute;
  left: 10px;
  top: 10px;
  height: 20px;
  border-radius: 50%;
  background: transparent;
  border: none;
  cursor: pointer;
  font-size: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.controls-group {
  display: flex;
  gap: 15px;
  justify-content: center;
  align-items: center;
}

.hidden-input {
  display: none;
}

.upload-button {
  padding: 10px 20px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.upload-button:hover {
  background-color: #0056b3;
}

.element-selector {
  padding: 10px 20px;
  border: 1px solid #007bff;
  border-radius: 4px;
  background-color: white;
  color: #007bff;
  cursor: pointer;
  transition: all 0.3s;
}

.content-wrapper {
  display: flex;
  gap: 20px;
  padding: 20px;
}

.design-container {
  position: relative;
  width: 450px;
  height: 600px;
  margin: 0 auto;
  background-color: #fff;
  background-image: radial-gradient(circle at 5px 5px, #e0e0e0 1px, transparent 1px);
  background-size: 10px 10px;
  box-shadow: 0 0 20px rgba(0,0,0,0.1);
  overflow: hidden;
}

.download-button {
  padding: 12px;
  font-size: 16px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  width: 100%;
  margin-top: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  transition: background-color 0.3s;
}

.download-button:hover {
  background-color: #0056b3;
}

/* Скрываем дефолтный option в выпадающем списке */
.element-selector option[value=""][disabled] {
  display: none;
}

/* Стиль для плейсхолдера */
.element-selector option[value=""][disabled] {
  color: #999;
}

.draggable-element {
  transition: transform 0.1s;
  
}

.dragging-overlay {
  position: fixed;
  pointer-events: none;
  z-index: 9999;
  border: 1px solid #007bff;
  box-sizing: border-box;
  opacity: 0;
  transition: opacity 0.1s;
  background: rgba(0,123,255,0.1);
  will-change: transform; /* Оптимизация для анимации */
}

.dragging-overlay.visible {
  opacity: 1;
  transition: none; /* Убираем переход для мгновенного появления */
}

.resize-handle {
  position: absolute;
  right: -8px;
  bottom: -8px;
  width: 16px;
  height: 16px;
  background-color: #007bff;
  border-radius: 50%;
  cursor: nwse-resize;
  z-index: 1000; 
  opacity: 0;
  transition: opacity 0.2s;
}

.draggable-element:hover .resize-handle {
  opacity: 1;
}

.remove-handle {
  position: absolute;
  top: -14px;
  right: -12px;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background-color: red;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border: none;
  font-size: 12px;
  z-index: 1000;
  opacity: 0;
  transition: opacity 0.2s;
}

.draggable-element:hover .remove-handle {
  opacity: 1;
}

.color-change {
  position: absolute;
  right: -12px;
  top: 50%;
  transform: translateY(-50%);
  width: 20px;
  height: 20px;
  border-radius: 4px;
  background: transparent;
  border: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
  transition: opacity 0.2s, transform 0.2s;
}

.draggable-element:hover .color-change {
  transform: translateY(-50%) scale(1.1);
}

.text-content-wrapper {
  position: relative;
  display: inline-block;
  white-space: nowrap; /* Чтобы текст не переносился при перетаскивании */
}

.text-input {
  font-size: 24px;
  font-family: 'Arial';
  color: #333;
  border: 1px solid #ccc;
  border-radius: 4px;
  padding: 2px 5px;
  width: 200px;
  outline: none;
  transition: all 0.2s;
  outline: none;
  word-break: 'break-all'
}

.text-change {
  position: absolute;
  right: 0;
  bottom: 0;
  transform: translate(50%, 50%); /* Смещение на половину размера кнопки */
  background: #fff;
  border: 1px solid #ccc;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  padding: 0;
  z-index: 1000;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
  transition: opacity 0.2s, transform 0.2s;
}

.draggable-element:hover .text-change {
  transform: translate(50%, 50%) scale(1.1);
  background: #f0f0f0;
}

.sidebar {
  width: 300px;
  background: #f5f5f5;
  padding: 15px;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  
}

.elements-list {
  margin-bottom: 20px;
}

.element-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  margin: 5px 0;
  background: white;
  border-radius: 4px;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
}
 
.element-info {
  display: flex;
  gap: 8px;
}

.element-controls {
  display: flex;
  gap: 5px;
}

.move-button {
  padding: 5px;
  border: none;
  background: none;
  cursor: pointer;
}

.move-button:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.remove-button {
  color: red;
  border: none;
  background: none;
  cursor: pointer;
  font-size: 16px;
}
